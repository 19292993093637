import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { Button, Icon, Label, Input, Title } from '@upsales/components';
import './BookingForm.scss';
import PhoneInput from '../PhoneInput';
import axios from 'axios';
import _ from 'lodash';
import AsyncCreatableSelect from 'react-select/async-creatable';
import config from './../../config';

type CompanyInfo = {
	name: string;
	prospectingId?: string;
	city?: string;
	dunsNo?: string;
};

interface FormData {
	name: string;
	email: string;
	company: CompanyInfo | null;
	phone: string;
	time: string;
	date: string;
}

type ProspectingData = {
	data: Array<CompanyInfo>;
	metadata: {
		total: number;
	};
};

interface Props {
	time: string;
	date: string;
	companyFieldSuggestions: boolean;
	phoneFieldRequired: boolean;
	prospectingCountry: string | null;
	onBackButton(active: boolean): void;
	onSubmit(data: FormData): void;
}

const getProspectingSearchUrl = (term: string, country: string) =>
	// if prospecting is broken in your dev environment switch config.API to 'https://power.upsales.com' while developing,
	// but don't forget to change it back!
	`${config.API}/api/external/prospecting/clientSearch?country=${country}&name=${term}`;

const _loadSuggestions = (country: string) => (query: string, callback: (options: CompanyInfo[]) => void) => {
	if (query.length >= 3) {
		axios
			.get<ProspectingData>(getProspectingSearchUrl(query, country))
			.then(res => callback(res.data.data))
			.catch(e => console.error(e));
	} else {
		callback([]);
	}
};

function BookingForm(props: Props) {
	const { t } = useTranslation();
	const { time, date, onSubmit, onBackButton } = props;
	const defaultData: FormData = {
		name: '',
		email: '',
		company: null,
		phone: '',
		time,
		date
	};
	const [inputs, setInputs] = useState<FormData>(defaultData);
	const [unMounted, setUnmounted] = useState(true);

	const loadSuggestions = useMemo(() => _.debounce(_loadSuggestions(props.prospectingCountry ?? ''), 400), [
		props.prospectingCountry
	]);

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		onSubmit(inputs);
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e) {
			e.persist();
			setInputs(inputs => ({ ...inputs, [e.target.name]: e.target.value }));
		}
	};
	const handlePhoneChange = (phone: string) => {
		setInputs(inputs => ({ ...inputs, phone: phone }));
	};

	useEffect(() => {
		return () => {
			setUnmounted(false);
		};
	}, []);

	const hasRequiredData = !!(
		inputs.name.length &&
		inputs.email.length &&
		inputs.company !== null &&
		inputs.company.name.length
	);

	return (
		<div className="BookingForm">
			<div
				className="BackButton"
				onClick={() => {
					onBackButton(false);
				}}
				data-testid="backButton"
			>
				<Icon name="angle-left" />
				<div className="BackButtonText">{t('bookingForm.backButton')}</div>
			</div>
			<Title size="xl" bold={true}>
				{t('bookingForm.details')}
			</Title>

			<form onSubmit={handleSubmit}>
				<Label required={true}>{t('bookingForm.name')}</Label>
				<Input
					required={true}
					onChange={handleInputChange}
					value={inputs.name}
					name="name"
					data-testid="name"
				/>
				<Label required={true}>{t('bookingForm.email')}</Label>
				<Input
					required={true}
					onChange={handleInputChange}
					value={inputs.email}
					name="email"
					type="email"
					data-testid="email"
				/>
				<Label required={props.phoneFieldRequired}>{t('bookingForm.phone')}</Label>
				<PhoneInput
					name="phone"
					iconClass="phone"
					onChange={handlePhoneChange}
					isMounted={unMounted}
					required={props.phoneFieldRequired}
				/>
				<Label required={true}>{t('bookingForm.company')}</Label>
				{props.companyFieldSuggestions ? (
					<AsyncCreatableSelect
						onChange={value => {
							if (value) setInputs(inputs => ({ ...inputs, company: value }));
						}}
						value={inputs.company}
						loadOptions={loadSuggestions}
						getOptionLabel={o => o.name}
						getOptionValue={o => o.name}
						getNewOptionData={input => ({ name: input })}
						isMulti={false}
						required={true}
						name="company"
						data-testid="company"
						noOptionsMessage={() => t('bookingForm.companySearchHint')}
						placeholder=""
					/>
				) : (
					<Input
						required={true}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							if (e) {
								e.persist();
								setInputs(inputs => ({ ...inputs, [e.target.name]: { name: e.target.value } }));
							}
						}}
						value={inputs.company?.name ?? ''}
						name="company"
						data-testid="company"
					/>
				)}

				<Button
					submit
					className="BookingFormConfirmButton"
					data-testid="confirmButton"
					disabled={!hasRequiredData}
				>
					<div className="BookingFormConfirmButtonTitle">{t('bookingForm.confirm')}</div>
					{/* TODO: Make these handle date according to user locale */}
					<div className="BookingFormConfirmButtonSubtitle">
						{moment(date.toString()).format('dddd Do MMMM')} {t('bookingForm.at')} {time}
					</div>
				</Button>
			</form>
		</div>
	);
}

export default BookingForm;
