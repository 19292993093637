import React, { useState, useEffect } from 'react';
import './App.scss';
import SideView from './components/SideView';
import { Row, Loader, Text, Logo } from '@upsales/components';
import BookingCalendar from './components/BookingCalendar';
import BookingForm from './components/BookingForm';
import Error500 from './components/Error/Error500';
import Error404 from './components/Error/Error404';
import Error400 from './components/Error/Error400';
import Error400InactivePage from './components/Error/Error400InactivePage';
import NoAvailable from './components/NoAvailableTimes';
import BookingConfirmed from './components/BookingConfirmed';
import axios from 'axios';
import 'moment-timezone';
import moment from 'moment';
import _ from 'lodash';
import config from './config';
import { Typography, TypographyDefaultFonts } from './types/typography';
import { Colors } from './types/colors';
import { useTranslation } from 'react-i18next';

interface TimeStartEnd {
	startTime: string | moment.Moment;
	endTime: string | moment.Moment;
}
interface TempTimes {
	[key: string]: TimeStartEnd[];
}
interface PerLengthTimes {
	[key: number]: TempTimes;
}

interface AppState {
	name: string;
	title: string;
	descriptionTitle: string;
	descriptionTime: number[];
	descriptionText: string;
	avatarSrc: string;
	email: string;
	phone: string;
	companyLogo: string;
	formActive: boolean;
	currAppointmentTimes: any;
	allAppointmentTimes: PerLengthTimes;
	apiError: boolean;
	notFound: boolean;
	badRequest: boolean;
	inactivePage: boolean;
	errorMessage: string;
	showBookedPage: boolean;
	bookingData: {
		name: string;
		email: string;
		phone: string;
		company: string;
		time: string;
		date: string;
	};
	showPhoneEmail: boolean;
	embedDisplayLogo: boolean;
	appointmentLengths: number[];
	isShared: boolean;
	companyName: string | null;
	companyFieldSuggestions: boolean;
	phoneFieldRequired: boolean;
	prospectingCountry: string | null;
}

function App() {
	const [state, setstate] = useState<AppState>({
		name: '',
		title: '',
		descriptionTitle: '',
		descriptionTime: [],
		descriptionText: '',
		avatarSrc: '',
		email: '',
		phone: '',
		companyLogo: '',
		formActive: false,
		currAppointmentTimes: {},
		allAppointmentTimes: {},
		apiError: false,
		notFound: false,
		badRequest: false,
		inactivePage: false,
		errorMessage: '',
		showBookedPage: false,
		bookingData: {
			name: '',
			email: '',
			phone: '',
			company: '',
			time: '',
			date: ''
		},
		showPhoneEmail: false,
		embedDisplayLogo: false,
		appointmentLengths: [],
		isShared: false,
		companyName: null,
		companyFieldSuggestions: true,
		phoneFieldRequired: false,
		prospectingCountry: null
	});
	const [loading, setLoading] = useState(true);
	const [stateLoaded, setStateLoaded] = useState(false);
	const [confirmationLoader, setConfirmationLoader] = useState(false);
	const [time, setSelectedTime] = useState('');
	const [date, setSelectedDate] = useState('');
	const [displayTimes, setDisplayTimes] = useState({});
	const [timezone, setTimezone] = useState('');
	const [colors, setColors] = useState<Colors>({
		mainBg: '',
		sidePanelBg: '',
		descriptionText: '',
		messageText: '',
		headlineText: '',
		butttonBg: '',
		butttonText: '',
		timeText: ''
	});
	const [typography, setTypography] = useState<Typography>({
		description: { typography: '', type: '' },
		message: { typography: '', type: '' },
		headlines: { typography: '', type: '' },
		buttonsCalendarDays: { typography: '', type: '' }
	});
	const [displayLogo, setDisplayLogo] = useState<boolean>(state.embedDisplayLogo);
	const [hash, setHash] = useState<string | undefined>('');

	const urlMetadata = `${config.API}/api/external/easyBookingMetadata`;
	const urlBooking = `${config.API}/api/external/easyBooking`;

	const getColorFonts = () => {
		const urlSearchParams = new URLSearchParams(
			window.location.search
				.split('=')
				.map(value => value.replace('&amp', '&'))
				.join('=')
		);
		const params = Object.fromEntries(urlSearchParams.entries());

		const defaultFonts: TypographyDefaultFonts = {
			Arial: 'Arial, Helvetica, sans-serif',
			Montserrat: 'Montserrat',
			'Arial Black': 'Arial Black, Gadget, sans-serif',
			'Comic Sans MS': 'Comic Sans MS, cursive, sans-serif',
			Impact: 'Impact, Charcoal, sans-serif',
			'Lucida Sans Unicode': 'Lucida Sans Unicode, Lucida Grande, sans-serif',
			Tahoma: 'Tahoma, Geneva, sans-serif',
			'Trebuchet MS': 'Trebuchet MS, Helvetica, sans-serif',
			Verdana: 'Verdana, Geneva, sans-serif',
			Georgia: 'Georgia, serif',
			'Palatino Linotype': 'Palatino Linotype, Book Antiqua, Palatino, serif',
			'Times New Roman': 'Times New Roman, Times, serif',
			'Courier New': 'Courier New, Courier, monospace',
			'Lucida Console': 'Lucida Console, Monaco, monospace'
		};
		const defaultTypes = ['bold', 'underline', 'italic', ''];

		let newColors: Colors = {
			mainBg: '',
			sidePanelBg: '',
			descriptionText: '',
			messageText: '',
			headlineText: '',
			butttonBg: '',
			butttonText: '',
			timeText: ''
		};
		let newTypography: Typography = {
			description: { typography: '', type: '' },
			message: { typography: '', type: '' },
			headlines: { typography: '', type: '' },
			buttonsCalendarDays: { typography: '', type: '' }
		};

		for (const [key, value] of Object.entries(params)) {
			if (key in colors) {
				newColors = { ...newColors, [key]: value };
			} else if (key in typography) {
				const values = value.split(',');
				let chosenTypography = '';
				let type = '';

				values.forEach((v: string) => {
					if (v in defaultFonts) {
						chosenTypography = defaultFonts[v];
					} else if (defaultTypes.includes(v)) {
						type = v;
					}
				});

				newTypography = { ...newTypography, [key]: { typography: chosenTypography, type: type } };
			} else if (key === 'display_logo') {
				setDisplayLogo(!!parseInt(value));
			}
		}

		document.body.style.background = newColors.mainBg;

		setColors(newColors);
		setTypography(newTypography);
	};

	const { t } = useTranslation();

	const lang = {
		easyBookingBy: t('main.easyBookingBy')
	};

	function setFormActive(active: boolean) {
		if (active === false && state.showBookedPage === false) {
			setSelectedTime('');
			setSelectedDate('');
		}

		setstate({ ...state, formActive: active });
	}

	const setAppointmentTimes = (appointmentLength: number) => {
		setDisplayTimes(state.allAppointmentTimes[appointmentLength]);
	};

	const changeTimezone = (newTimezone: string) => {
		if (timezone) {
			const appointmentTimes: TempTimes = _.cloneDeep(state.currAppointmentTimes);

			for (const [key, times] of Object.entries(appointmentTimes)) {
				let timesShifted = 0;

				times.forEach(time => {
					time.startTime = moment.tz(time.startTime, newTimezone);
					time.endTime = moment.tz(time.endTime, newTimezone);

					if (time.startTime.format('YYYY-MM-DD') !== key) {
						timesShifted++;
					}

					// If all times that day are shifted to another date, change the key for accurate render in calendar
					if (timesShifted === times.length) {
						const newTime = moment(time.startTime).format('YYYY-MM-DD');

						delete Object.assign(appointmentTimes, { [newTime]: times }).key;
					}
				});
			}

			setTimezone(newTimezone);
			setDisplayTimes(appointmentTimes);
		} else {
			setDisplayTimes(state.currAppointmentTimes);
		}
	};

	const handleBookingConfirmation = async (data: any) => {
		if (data?.company?.name) {
			data.company.name = data.company.name.trim();
		}
		const times = data.time.split('-');
		const dayAndMonth = moment(data.date).locale('en').format('YYYY-MM-DD');
		const time = moment
			.tz(dayAndMonth + ' ' + times[0].trim(), timezone)
			.utc()
			.format();
		const endTime = moment
			.tz(dayAndMonth + ' ' + times[1].trim(), timezone)
			.utc()
			.format();
		const appointmentData = {
			name: data.name,
			email: data.email,
			company: data.company,
			phone: data.phone,
			time: time,
			timeEnd: endTime
		};
		setConfirmationLoader(true);

		axios({
			method: 'POST',
			url: `${urlBooking}/${hash}`,
			data: appointmentData
		})
			.then(res => {
				if (res.status === 200) {
					setstate({ ...state, bookingData: data, showBookedPage: true });
				}
				if (window.parent) {
					try {
						window.parent.postMessage(
							{
								event: 'upsales.appointment_booked'
							},
							'*'
						);
					} catch (e) {
						console.log('Failed to postMessage', e);
					}
				}
			})
			.then(() => {
				setConfirmationLoader(false);
			})
			.catch(e => {
				console.error(e);
				handleError(e.response);
			})
			.finally(() => {
				setConfirmationLoader(false);
			});
	};

	useEffect(() => {
		var locale = 'sv';
		if (window.navigator.language) {
			// Split needed as firefox saves in different format than moment wants
			locale = window.navigator.language.split('-')[0];
			if (locale !== 'en') {
				try {
					require(`moment/locale/${locale}`);
				} catch (e) {
					locale = 'en';
					console.error(e);
				}
			}
			moment.locale(locale);
			// Override to start week on monday, according to ISO-8601
			moment.updateLocale(locale, {
				week: {
					dow: 1, // First day of week is Monday
					doy: 4 // First week of year must contain 4 January
				}
			});
		}
		const hash = window.location.href
			.split('/')
			.filter(e => e !== '')
			.pop();
		setHash(hash);
		let imgExists = false;
		axios({ method: 'GET', url: `${urlMetadata}/profile/${hash}` })
			.then(() => {
				imgExists = true;
			})
			.catch(() => {
				imgExists = false;
			})
			.finally(() => {
				axios({
					method: 'GET',
					url: `${urlMetadata}/${hash}`
				})
					.then(res => {
						const handleAppointmentTimes = () => {
							const timezone = moment.tz.guess();
							setTimezone(timezone);
							const tempTimes: PerLengthTimes = _.cloneDeep(res.data.availableTimes);
							Object.values(tempTimes).forEach((perLength: TempTimes) => {
								for (const [, times] of Object.entries(perLength)) {
									times.forEach(time => {
										time.startTime = moment(time.startTime);
										time.endTime = moment(time.endTime);
									});
								}
							});
							return tempTimes;
						};
						const tempAllAppointmentTimes = handleAppointmentTimes();
						setstate({
							...state,
							name: res.data.user?.name ?? '',
							title: res.data.user?.title ?? '',
							email: res.data.user?.email ?? '',
							phone: res.data.user?.phone ?? '',
							descriptionTitle: res.data.title,
							showPhoneEmail: res.data.showContactInfo,
							isShared: res.data.isShared,
							companyFieldSuggestions: res.data.companyFieldSuggestions,
							prospectingCountry: res.data.prospectingCountry,
							phoneFieldRequired: res.data.phoneFieldRequired,
							descriptionTime: res.data.appointmentLengths,
							descriptionText: res.data.message,
							avatarSrc: imgExists ? `${urlMetadata}/profile/${hash}` : res.data.user?.avatar ?? '',
							companyLogo: res.data.companyLogo,
							embedDisplayLogo: res.data.embedDisplayLogo,
							allAppointmentTimes: tempAllAppointmentTimes,
							currAppointmentTimes: Object.values(tempAllAppointmentTimes)[0],
							appointmentLengths: res.data.appointmentLengths.sort((a: number, b: number) => {
								return a - b;
							}),
							companyName: res.data.companyName
						});

						setDisplayLogo(res.data.embedDisplayLogo);

						if (res.data.isShared) {
							getColorFonts();
						}
					})
					.catch(e => {
						console.error(e);
						handleError(e.response);
					})
					.then(() => {
						setLoading(false);
						setStateLoaded(true);
					})
					.catch(e => {
						console.error(e);
						handleError(e.response);
					});
			});
	}, []);

	useEffect(() => {
		if (stateLoaded) {
			changeTimezone(timezone);
		}
	}, [stateLoaded]);

	function handleError(res: any) {
		if (res && res.statusText) {
			setstate({ ...state, errorMessage: res.statusText });
		}
		if (res && res.status) {
			switch (res.status) {
				case 400:
					if (res.data.error.key === 'InactiveBookingPage') {
						setstate({ ...state, inactivePage: true });
					} else {
						setstate({ ...state, badRequest: true });
					}
					break;
				case 404:
					setstate({ ...state, notFound: true });
					break;
				case 500:
					setstate({ ...state, apiError: true });
					break;
				default:
					setstate({ ...state, apiError: true });
					break;
			}
		} else {
			setstate({ ...state, apiError: true });
		}
	}

	return (
		<div className="App" data-testid="appTest">
			{state.notFound || state.apiError || state.inactivePage ? (
				state.notFound ? (
					<Error404 companyLogo={state.companyLogo} />
				) : state.inactivePage ? (
					<Error400InactivePage />
				) : (
					<Error500 />
				)
			) : loading ? (
				<Loader size="lg" id="pageLoader" />
			) : (
				<Row id="pageWrapper">
					<Row id="mainWrap">
						<aside id="sideView">
							{/**Check if isShared otherwise null */}
							<SideView
								colors={state.isShared ? colors : null}
								typography={state.isShared ? typography : null}
								displayLogo={displayLogo}
								userInfo={state}
								time={time}
								date={date}
								showDescription
								showPhoneEmail={state.showPhoneEmail}
								isShared={state.isShared}
							/>
						</aside>
						<main id="mainContent">
							{state.currAppointmentTimes && Object.keys(state.currAppointmentTimes).length ? (
								<div id="calendarWrapper" data-testid="calendarTestWrapper">
									{state.badRequest ? (
										<Error400 />
									) : state.showBookedPage ? (
										<BookingConfirmed timezone={timezone} data={state.bookingData} />
									) : state.formActive ? (
										confirmationLoader ? (
											<Loader size="md" id="confirmationLoader" />
										) : (
											<BookingForm
												time={time}
												date={date}
												companyFieldSuggestions={state.companyFieldSuggestions}
												phoneFieldRequired={state.phoneFieldRequired}
												prospectingCountry={state.prospectingCountry}
												onBackButton={setFormActive}
												onSubmit={handleBookingConfirmation}
											/>
										)
									) : (
										/**Check if isShared otherwise null */
										<BookingCalendar
											timezone={timezone}
											setDate={setSelectedDate}
											setTime={setSelectedTime}
											setFormActive={setFormActive}
											setTimezone={changeTimezone}
											appointmentTimes={displayTimes}
											colors={state.isShared ? colors : null}
											typography={state.isShared ? typography : null}
											appointmentLengths={state.appointmentLengths}
											setAppointmentTimes={setAppointmentTimes}
										/>
									)}
								</div>
							) : (
								<NoAvailable name={state.name} />
							)}
						</main>
					</Row>
					<Row id="poweredUpsales">
						<Text size="sm" style={colors ? { color: colors.headlineText } : {}}>
							{lang.easyBookingBy}
						</Text>{' '}
						<Logo color="green" data-testid="upsalesLogo" />
					</Row>
				</Row>
			)}
		</div>
	);
}

export default App;
